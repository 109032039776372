<template>
  <div
    id="app"
    class="surfe h-100"
  >
    <component
      :is="layout"
      v-if="loaded"
    >
      <router-view />
    </component>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeColors, $themeBreakpoints } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { useWindowSize, useCssVar } from '@vueuse/core'
import { watch } from '@vue/composition-api'
import store from '@/store'
import { sleep, isCorrectUser } from '@/utils'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutArticle = () => import('@/layouts/article/LayoutArticle.vue')
const LayoutEmbed = () => import('@/layouts/EmbedLayout.vue')

export default {
  components: {
    LayoutVertical,
    LayoutFull,
    LayoutArticle,
    LayoutEmbed,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (this.$route.meta.layout === 'article') return 'layout-article'
      if (this.$route.meta.layout === 'embed') return 'layout-embed'
      return `layout-${this.$store.state.appConfig.layout.type}`
    },
  },
  async beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    document.documentElement.setAttribute('dir', 'ltr')
  },
  async mounted() {
    this.$router.onReady(async () => {
      if (!this.$route.meta.public && !this.$route.meta.eh) {
        // Load account & check for 401
        try {
          const { userID } = this.$route.query
          const isInvalidUser = userID && !isCorrectUser(userID)
          if (isInvalidUser) await this.requestNewToken()

          await this.$store.dispatch('api/loadAccount')

          const { account } = this.$store.state.api

          // Boot intercom
          window.Intercom('boot', {
            app_id: 'xtj47y4u',
            name: account.name,
            email: account.email,
            user_id: account.id,
            created_at: account.createdAt,
          })
        } catch (err) {
          if (err?.response?.status === 401) {
            localStorage.clear()
            try {
              await this.requestNewToken()
              await this.$store.dispatch('api/loadAccount')
            } catch (err_) {
              console.error(err_)
            }
          }
        }
      } else {
        // Boot intercom
        window.Intercom('boot', { app_id: 'xtj47y4u' })
      }

      // Hide spinner
      this.loaded = true
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) appLoading.style.display = 'none'

      // Load all the state
      if (!this.$route.meta.public && !this.$route.meta.eh) {
        try {
          await this.$store.dispatch('api/loadAll')
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
          throw error
        }
      }
    })
  },
  setup() {
    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('appConfig/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('appConfig/UPDATE_WINDOW_WIDTH', val)
    })
  },
  methods: {
    async requestNewToken() {
      // Ask extension for token
      for (let i = 0; i < 15; i++) {
        window.postMessage('injectToken', '*')
        await sleep(100)
        if (localStorage.getItem('key')) break
      }
    },
  },
}
</script>

<style>

input[type="checkbox"].custom-control-input {
  display: none;
}

</style>
