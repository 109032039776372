/* eslint-disable no-return-assign */
/* eslint-disable no-empty-pattern */
import axios from 'axios'
import { datadogLogs } from '@datadog/browser-logs'
import { authHeadersWithVerificationToken } from '@/utils'

const { authHeaders } = require('../../utils')

const TEMPLATE_USER_ANALYTICS_ENABLED = process.env.VUE_APP_TEMPLATE_USER_ANALYTICS_ENABLED === 'true'
const CORE_API_URL = process.env.VUE_APP_CORE_API_URL
const EH_API_URL = process.env.VUE_APP_EH_API_URL

export default {
  namespaced: true,
  state: {
    loading: {
      account: false,
      users: false,
      customer: false,
      payment: false,
      invoices: false,
    },
    account: {},
    userStatus: {},
    templateAnalytics: [],
    userAnalytics: [],
    connectionRequestAnalytics: [],
    users: {},
    actions: [],
    actionFeed: [],
    tools: {},
    crmFields: {},
    crmOwners: {},
    customer: {},
    invoices: [],
    paymentMethods: {},
    paymentIntents: {},
    enrichmentLogs: [],
    fieldsMappingLogs: [],
    featureFlags: [],
    teams: [],
    ehQueries: [],
  },
  getters: {
    getDefaultFields(state) {
      if (!state.crmFields.defaultFields) return {}
      const fields = {};
      ['contact', 'lead', 'org', 'deal'].forEach(key => {
        fields[key] = (state.crmFields.defaultFields[key] || []).map(field => {
          const af = (state.crmFields.availableFields[key] || []).filter(f => f.id === field.id)[0] || {}
          return { ...field, type: af.type, options: af.options }
        })
      })
      return fields
    },
    getPaymentIntentError(state) {
      const { paymentIntents } = state
      if (!paymentIntents || !Array.isArray(paymentIntents) || paymentIntents.length < 1) return false

      const sorted = paymentIntents.sort((a, b) => b.created - a.created)
      return sorted[0] && !!sorted[0].last_payment_error
    },
  },
  mutations: {
    SET_LOADING(state, key) {
      if (!(key in state.loading)) return
      state.loading[key] = true
    },
    SET_LOADED(state, key) {
      if (!(key in state.loading)) return
      state.loading[key] = false
    },
    UPDATE_ACCOUNT(state, value) {
      state.account = value
    },
    UPDATE_TEMPLATE_ANALYTICS(state, value) {
      state.templateAnalytics = value
    },
    UPDATE_USER_ANALYTICS(state, value) {
      state.userAnalytics = value
    },
    CONNECTION_REQUEST_ANALYTICS(state, value) {
      state.connectionRequestAnalytics = value
    },
    UPDATE_USERS(state, value) {
      state.users = value
    },
    UPDATE_ACTIONS(state, value) {
      state.actions = value
    },
    UPDATE_FEED(state, value) {
      state.actionFeed = value
    },
    UPDATE_TOOLS(state, value) {
      state.tools = value
    },
    UPDATE_CRM_OWNERS(state, value) {
      state.crmOwners = value
    },
    UPDATE_CRM_FIELDS(state, value) {
      state.crmFields = value
    },
    UPDATE_CUSTOMER(state, value) {
      state.customer = value
    },
    UPDATE_INVOICES(state, value) {
      state.invoices = value
    },
    UPDATE_PAYMENT_METHODS(state, value) {
      state.paymentMethods = value
    },
    UPDATE_PAYMENT_INTENTS(state, value) {
      state.paymentIntents = value
    },
    UPDATE_ENRICHMENT_LOGS(state, value) {
      state.enrichmentLogs = value
    },
    UPDATE_FIELDS_MAPPING_LOGS(state, value) {
      state.fieldsMappingLogs = value
    },
    UPDATE_FEATURE_FLAGS(state, value) {
      state.featureFlags = value
    },
    UPDATE_TEAMS(state, value) {
      state.teams = value
    },
    UPDATE_EH_QUERIES(state, value) {
      state.ehQueries = value.requests
      state.remainingCredits = value.remainingCredits
    },
    UPDATE_USER_STATUS(state, value) {
      state.userStatus = value
    },
  },
  actions: {
    // Authentication
    async sendMagicLink({ }, values) {
      await axios.post(`${CORE_API_URL}/magic-link`, values)
    },

    // Account
    async loadAccount({ commit }) {
      commit('SET_LOADING', 'account')
      try {
        const url = `${CORE_API_URL}/auth/account`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_ACCOUNT', data)

        if (process.env.VUE_APP_DATADOG_CLIENT_TOKEN) {
          const { Email, CompanyKey } = data
          datadogLogs.addLoggerGlobalContext('email', Email)
          datadogLogs.addLoggerGlobalContext('company_key', CompanyKey)
        }
      } finally {
        commit('SET_LOADED', 'account')
      }
    },
    async loadTemplateAnalytics({ commit }) {
      const url = `${CORE_API_URL}/admin/conversation/analytics/templates`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_TEMPLATE_ANALYTICS', data)
    },
    async loadUserAnalytics({ commit }) {
      const url = `${CORE_API_URL}/admin/conversation/analytics/users`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_USER_ANALYTICS', data)
    },
    async loadConnectionRequestAnalytics({ commit }) {
      const url = `${CORE_API_URL}/auth/linkedin/connection-request-stats`
      const { data } = await axios.get(url, authHeaders())
      commit('CONNECTION_REQUEST_ANALYTICS', data)
    },
    async loadTeams({ commit }) {
      const { data } = await axios.get(`${CORE_API_URL}/auth/teams`, authHeaders())
      commit('UPDATE_TEAMS', data)
    },
    async createTeam({ dispatch }, values) {
      await axios.post(`${CORE_API_URL}/admin/teams`, { name: values.name }, authHeaders())
      dispatch('loadTeams')
    },
    async updateAccount({ }, value) {
      const url = `${CORE_API_URL}/auth/account`
      await axios.put(url, value, authHeaders())
    },
    async deleteAccount({ }) {
      const url = `${CORE_API_URL}/admin/account`
      await axios.delete(url, authHeaders())
    },
    async deleteAccountEvent({ }, value) {
      const url = `${CORE_API_URL}/auth/feedback/delete-account`
      await axios.post(url, value, authHeaders())
    },
    async retryStripePayment({ }) {
      const url = `${process.env.VUE_APP_CORE_API_URL}/admin/checkout/retry-payment`
      await axios.post(url, {}, authHeaders())
    },
    async applyPromoCode({ }, promoCode) {
      const url = `${CORE_API_URL}/admin/account/promo-code/${promoCode}`
      const response = await axios.put(url, {}, authHeaders())
      return !!response?.data
    },
    async switchCRM({ }, value) {
      const url = `${CORE_API_URL}/auth/feedback/switch-crm`
      await axios.post(url, value, authHeaders())
    },
    async loadNotificationSettings({ commit }, params) {
      const url = `${CORE_API_URL}/notification-settings/${params.userId}/${params.emailId}`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_ACCOUNT', data)
    },
    async updateNotificationSettings({ dispatch }, value) {
      const url = `${CORE_API_URL}/notification-settings/${value.userId}/${value.emailId}`
      await axios.put(url, value, authHeaders())
      dispatch('loadNotificationSettings')
    },
    async uninstallEvent({ }, value) {
      const url = `${CORE_API_URL}/uninstall/${value.userId ?? value.email}`
      await axios.post(url, value, authHeaders())
    },
    async contactUs({ }, value) {
      const url = `${CORE_API_URL}/auth/feedback/contact-us`
      await axios.post(url, value, authHeaders())
    },
    async loadActions({ commit }, v) {
      const actionType = v ? v.actionType ?? '' : ''
      const duration = v ? v.duration ?? '14' : '14'
      const url = `${CORE_API_URL}/auth/actions?actionType=${actionType}&duration=${duration}`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_ACTIONS', data)
    },

    // Users
    async loadUsers({ commit }) {
      commit('SET_LOADING', 'users')
      try {
        const url = `${CORE_API_URL}/auth/users`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_USERS', data)
      } finally {
        commit('SET_LOADED', 'users')
      }
    },
    async loadUserStatus({ commit }) {
      const url = `${CORE_API_URL}/auth/status`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_USER_STATUS', data)
    },
    async inviteUsers({ dispatch }, value) {
      const url = `${CORE_API_URL}/auth/users/invite`
      await axios.post(url, value, authHeaders())
      dispatch('loadUsers')
    },
    async updateUser({ dispatch }, { id, value }) {
      const url = `${CORE_API_URL}/auth/users/${id}`
      await axios.put(url, value, authHeaders())
      dispatch('loadUsers')
    },
    async updateUsers({ dispatch }, { users }) {
      const url = `${CORE_API_URL}/auth/users/batch`
      await axios.put(url, users, authHeaders())
      dispatch('loadUsers')
    },
    async updateUsersSecure({ dispatch }, { users }) {
      const url = `${CORE_API_URL}/auth/users/batch/secure`
      await axios.put(url, users, authHeadersWithVerificationToken())
      dispatch('loadUsers')
    },
    async deleteUser({ dispatch }, id) {
      const url = `${CORE_API_URL}/admin/users/${id}`
      await axios.delete(url, authHeaders())
      dispatch('loadUsers')
    },
    async loadFeed({ commit }) {
      const url = `${CORE_API_URL}/auth/feed`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_FEED', data)
    },

    // CRM Fields
    async loadCRMOwners({ commit }) {
      const url = `${CORE_API_URL}/auth/crm-owners?account=true`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_CRM_OWNERS', data)
    },
    async loadEmptyCRMOwners({ commit }) {
      commit('UPDATE_CRM_OWNERS', [])
    },
    async loadCRMFields({ commit }) {
      const url = `${CORE_API_URL}/auth/fields/crm?account=true`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_CRM_FIELDS', data)
    },
    async updateCRMFields({ }, LinkedinToCRM) {
      const custom = JSON.parse(JSON.stringify(LinkedinToCRM))
      const url = `${CORE_API_URL}/auth/fields`
      await axios.put(url, { custom }, authHeaders())
    },
    async updateDefaultFields({ state }, fields) {
      const url = `${CORE_API_URL}/auth/fields/default`
      state.crmFields.defaultFields = fields
      await axios.put(url, fields, authHeaders())
    },

    // Tools
    async loadTools({ commit }) {
      const url = `${CORE_API_URL}/auth/tools`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_TOOLS', data)
    },
    async updateTool({ dispatch, state }, value) {
      const url = `${CORE_API_URL}/auth/tools`
      if (!value.category) return

      const currentValue = state.tools[value.category][value.name.toUpperCase()]

      // Swap order if needed
      // eslint-disable-next-line no-restricted-syntax
      for (const [name, tool] of Object.entries(state.tools[value.category])) {
        if (name !== value.name.toUpperCase() && tool.order === value.order) {
          state.tools[value.category][name].order = currentValue.order
        }
      }
      state.tools[value.category][value.name.toUpperCase()] = value
      await axios.put(url, state.tools, authHeaders())
      dispatch('loadTools')
    },
    async loadEnrichmentLogs({ commit }) {
      const url = `${CORE_API_URL}/auth/enrichment-logs`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_ENRICHMENT_LOGS', data)
    },
    async loadFieldsMappingLogs({ commit }) {
      const url = `${CORE_API_URL}/auth/change-logs?type=update&path=CustomFields`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_FIELDS_MAPPING_LOGS', data)
    },
    async addToolSuggestion({ }, value) {
      const url = `${CORE_API_URL}/auth/feedback/request-tool`
      await axios.post(url, value, authHeaders())
    },
    async disconnectTool({ dispatch }, tool) {
      const url = `${CORE_API_URL}/auth/tool/${tool}`
      await axios.delete(url, authHeaders())
      dispatch('loadTools')
    },

    // Billing
    async loadCustomer({ commit }) {
      commit('SET_LOADING', 'customer')
      try {
        const url = `${CORE_API_URL}/admin/checkout/customer`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_CUSTOMER', data)
      } finally {
        commit('SET_LOADED', 'customer')
      }
    },
    async updateCustomer({ }, value) {
      const url = `${CORE_API_URL}/admin/checkout/customer`
      await axios.put(url, value, authHeaders())
    },
    async updateSubscription({ dispatch }, value) {
      const url = `${CORE_API_URL}/admin/checkout/subscription`
      await axios.put(url, value, authHeadersWithVerificationToken())
      await dispatch('loadCustomer')
    },
    async loadInvoices({ commit }) {
      commit('SET_LOADING', 'invoices')
      try {
        const url = `${CORE_API_URL}/admin/checkout/invoices`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_INVOICES', data)
      } finally {
        commit('SET_LOADED', 'payment')
      }
    },
    async updatePaymentMethod({ }, value) {
      const url = `${CORE_API_URL}/admin/account/pm`
      await axios.put(url, { paymentMethod: value }, authHeaders())
    },
    async loadPaymentMethods({ commit }) {
      commit('SET_LOADING', 'payment')
      try {
        const url = `${CORE_API_URL}/admin/checkout/pm`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_PAYMENT_METHODS', data)
      } finally {
        commit('SET_LOADED', 'payment')
      }
    },
    async loadPaymentIntent({ commit }) {
      const url = `${CORE_API_URL}/admin/checkout/pm/payment`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_PAYMENT_INTENTS', data)
    },
    async applyVAT({ }, vat) {
      const url = `${CORE_API_URL}/admin/checkout/vat/${vat}`
      await axios.put(url, {}, authHeaders())
    },
    async applyCoupon({ }, coupon) {
      const url = `${CORE_API_URL}/admin/checkout/coupon/${coupon}`
      const response = await axios.put(url, {}, authHeaders())
      return !!response?.data
    },

    // Enrichment Hub
    async loadEHQueries({ commit }) {
      const url = `${EH_API_URL}/auth/queries`
      const { data } = await axios.get(url, authHeaders(true))
      commit('UPDATE_EH_QUERIES', data)
    },
    async validateQuery({ }, id) {
      const url = `${EH_API_URL}/auth/queries/${id}/validate`
      await axios.patch(url, {}, authHeaders(true))
    },
    async cancelQuery({ }, id) {
      const url = `${EH_API_URL}/auth/queries/${id}/cancel`
      await axios.patch(url, {}, authHeaders(true))
    },

    // All
    async loadAll({ dispatch, state }) {
      const p = [dispatch('loadUsers'), dispatch('loadActions')]

      // Load remaining data if admin
      if (state.account.isAdmin) {
        p.push(dispatch('loadCustomer'))
        p.push(dispatch('loadPaymentMethods'))
        p.push(dispatch('loadPaymentIntent'))
        p.push(dispatch('loadInvoices'))

        if (TEMPLATE_USER_ANALYTICS_ENABLED) {
          p.push(dispatch('loadTemplateAnalytics'))
          p.push(dispatch('loadUserAnalytics'))
          p.push(dispatch('loadConnectionRequestAnalytics'))
        }
      }

      await Promise.all(p)

      if (state.account.hasCRMAccess) {
        try {
          await dispatch('loadCRMOwners')
        } catch (error) {
          console.error(error)
        }
      } else {
        await dispatch('loadEmptyCRMOwners')
      }

      await dispatch('loadFeed')
    },

    // Feature flags
    async loadFeatureFlags({ commit }) {
      const url = `${CORE_API_URL}/feature-flags`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_FEATURE_FLAGS', data)
    },
  },
}
