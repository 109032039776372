import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBToggle } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import moment from 'moment'
import axios from 'axios'
import router from './router'
import store from './store'
import App from './App.vue'
import * as logger from './logger'
import { isTokenValid } from './utils'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

Vue.prototype.moment = moment

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Directives
Vue.directive('b-toggle', VBToggle)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

logger.init()

axios.interceptors.request.use(
  request => {
    if (request.url.includes('/auth') && !isTokenValid()) {
      return Promise.reject(new Error('invalid token'))
    }
    return request
  },
  error => Promise.reject(error),
)

axios.interceptors.response.use(response => response, error => {
  if (error?.response?.status === 401 && router?.currentRoute?.fullPath !== '/login' && !router?.currentRoute?.fullPath.includes('/enrichment-hub/')) {
    return router.push('/login')
  }
  return Promise.reject(error)
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
